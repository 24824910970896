import { useEffect, useState } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { getTranslate } from "../contexts/Utils";
import Api from "../contexts/Api";

const StripeAccount = ({ lang }) => {
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user');

  const handleStripeConnection = async () => {
    const getAccountLoginLink = await Api({ endpoint: '/stripe/accounts/login' });
    if (getAccountLoginLink.success) {
      window.open(getAccountLoginLink.url, '_blank');
    }
  }

  const handleStripeInscription = async () => {
    const stripeAccount = await Api({ endpoint: '/stripe/accounts/link' });
    if (stripeAccount.success) {
      window.location.href = stripeAccount.url
    }
  }

  const handleStripeAccountCreation = async () => {
    await Api({ endpoint: '/stripe/accounts' })
    const stripeAccount = await Api({ endpoint: '/stripe/accounts/link' });
    window.location.href = stripeAccount.url
  }

  return <S.Container>
    <h2>Gestion de votre compte stripe</h2>
    {Boolean(userData.account_confirmed) && <div>
      <S.Button onClick={handleStripeConnection}>Accéder à mon espace</S.Button>
    </div>}
    {!userData.account_confirmed && userData.stripe_account && <div>
      <S.Button onClick={handleStripeInscription}>Finaliser mon inscription</S.Button>
    </div>}
    {!userData.account_confirmed && !userData.stripe_account && <div>
      <S.Button onClick={handleStripeAccountCreation}>Créer mon compte</S.Button>
    </div>}
  </S.Container >
}

export default StripeAccount;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;
  overflow-y: auto;
  height: '100%';

  & h2 {
    color: ${({ theme }) => theme.title} !important;
    font-size: 21px;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
    margin-bottom:20px
  }
`
S.Button = styled.button`
  margin-left: ${({ right }) => right ? 'auto' : 'unset'};
  background: ${({ theme }) => theme.linear};
  border: none;
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 28px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 35px;
  cursor: pointer;
`

S.CancelSubscription = styled.p`
  margin:0;
`